<template>
  <div
    class="block shrink grow basis-0 p-3 md:[&:first-child]:pl-0 md:[&:last-child]:pr-0"
    :class="`text-${block.textAlign}`"
  >
    <div
      class="column-box h-full rounded"
      :class="`${block.backgroundColor} ${additionalColClass}`"
    >
      <PageBuilder :blocks="block.children" :globals="globals" />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
});

const additionalColClass = props.block.backgroundColor ? 'p-8' : '';
</script>
